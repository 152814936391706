<template>
    <div v-if="joinSuccess || apptSuccess">
        <div class="mt-4 mb-8 flex place-content-center place-items-center" v-if="joinSuccess || apptSuccess">
            <div class="mr-4 w-12 h-12 flex justify-center items-center border-solid border-2 border-success rounded-full">
                <feather-icon class="text-success" icon="CheckIcon" svgClasses="h-6 w-6" />
            </div>
            <span class="text-xl font-semibold" v-if="joinSuccess">義檢活動報名成功</span>
            <span class="text-xl font-semibold" v-if="apptSuccess">掛號成功</span>
        </div>
        <div class="flex justify-center" v-if="apptSuccess">
            <vs-button class="rounded whitespace-no-wrap" @click="close">關閉</vs-button>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        joinSuccess: {
            type: Boolean,
            required: true,
            default: false,
        },
        apptSuccess: {
            type: Boolean,
            required: true,
            default: false,
        },
    },
    methods: {
        close() {
            this.$bus.$emit('close')
        },
    },
}
</script>
