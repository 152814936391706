<template>
    <div>
        <div class="mb-base grid grid-cols-1 gap-12">
            <div v-for="item in items">
                <div class="grid grid-cols-1 gap-4">
                    <div>
                        <span class="font-semibold">{{ getItemName(item.item_code) }}</span>
                    </div>
                    <div class="px-4" v-for="mtype in item.mtype">
                        <div class="mb-2">
                            <span>{{ getMtypeName(item.item_code, mtype.mtype_code) }}</span>
                        </div>
                        <!-- 特規 純音聽力圖 start -->
                        <div class="mb-2" v-if="mtype.mtype_id == 36">
                            <div class="grid grid-cols-10">
                                <div></div>
                                <div class="text-center" style="width: 50px" v-for="col in mtype.value_table_map.cols">
                                    {{ col }}
                                </div>
                            </div>
                            <div class="grid grid-cols-10 place-items-center" v-for="(row, index) in mtype.value_table_map.rows">
                                <div style="width: 50px">
                                    {{ row }}
                                </div>
                                <div class="text-center" style="width: 50px" v-for="(col, index2) in mtype.value_table_map.cols">
                                    <vs-input class="rounded-none" style="width: 50px" type="text" v-model="form['value_table'][index][index2]" v-if="form.value_table"></vs-input>
                                </div>
                            </div>
                        </div>
                        <!-- 特規 純音聽力圖 end -->
                        <div v-if="mtype.form_type == 'number'">
                            <vs-input class="w-auto" type="number" v-model.number="form[mtype.mtype_code]"></vs-input>
                        </div>
                        <div class="flex flex-wrap space-x-4" v-if="mtype.form_type == 'radio'">
                            <vs-radio v-for="(selection, index) in mtype.value_map" v-model.number="form[mtype.mtype_code]" :vs-name="mtype.mtype_code" :vs-value="index">{{ selection }}</vs-radio>
                        </div>
                        <div class="flex flex-wrap space-x-4" v-if="mtype.form_type == 'checkbox'">
                            <vs-checkbox v-for="(selection, index) in mtype.value_map" v-model="form[mtype.mtype_code]" :vs-name="mtype.mtype_code" :vs-value="index">{{ selection }}</vs-checkbox>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="px-4">
                <span>備註</span>
                <vs-input class="w-auto"></vs-input>
            </div> -->
            <!-- <vs-row class="mb-base" v-for="first in eventitemlist" v-if="select_type.length > 0 && (select_type[first.item_id] || select_type[0])">
                    <vs-col vs-w="12" vs-xs="12" class="px-2 cd-form-group">
                        <h5>{{ itemlist[first.item_id] }}</h5>
                    </vs-col>
                    <vs-col vs-w="12" vs-xs="12" class="px-2 cd-form-group">
                        <vs-input type="number" v-model.number="input[first.item_id][second.mtype_id]" v-for="second in first.mtype" :label="first.number > 1 ? second.name : ''" v-if="second.form_type == 'number'" />
                    </vs-col>
                    <vs-col class="cd-form-group px-4 grid">
                        <template v-for="(second, index2) in first.mtype">
                            <h5 v-if="second.form_type == 'radio' || second.form_type == 'checkbox'">{{ second.name }}</h5>
                            純音聽力圖特別設定
                            <vs-row class="mb-base p-4" v-if="value_table[first.item_id] != undefined && second.mtype_id == 36">
                                <vs-col vs-type="flex">
                                    <div style="width: 50px" />
                                    <div class="text-center" style="width: 50px" v-for="(data, col) in tb.cols" :key="col">
                                        {{ data }}
                                    </div>
                                </vs-col>
                                <vs-col vs-type="flex" vs-align="center" v-for="(data, row) in tb.rows" :key="row">
                                    <div style="width: 50px">
                                        {{ data }}
                                    </div>
                                    <div class="flex" style="border-bottom: 1px solid rgba(0, 0, 0, 0.2); border-right: 1px solid rgba(0, 0, 0, 0.2)">
                                        <div style="width: 50px" v-for="(data, col) in tb.cols" :key="col">
                                            <vs-input type="text" class="cd-appt-input table-input" v-model="value_table[first.item_id][second.mtype_id][row][col]" @change="change_mtype_select(0, first.item_id, second.mtype_id)" />
                                        </div>
                                    </div>
                                </vs-col>
                            </vs-row>
    
                            <vs-radio
                                class="my-1"
                                v-model="input[first.item_id][second.mtype_id]"
                                :vs-name="second.name"
                                v-for="(third, index3) in second.value_map"
                                :vs-value="index3.toString()"
                                v-if="second.form_type == 'radio'"
                                @change="change_mtype_select(third, first.item_id, second.mtype_id)"
                                :label="third"
                            >
                                {{ third }}
                            </vs-radio>
    
                            <vs-checkbox class="my-1" v-model="checkbox_data[first.item_id][second.mtype_id]" vs-name="kid" v-for="(third, index3) in second.value_map" :vs-value="index3" v-if="second.form_type == 'checkbox'" @change="change_mtype_select(third, first.item_id, second.mtype_id)">
                                <div style="display: flex; padding: 0rem 1rem">{{ third }}</div>
                            </vs-checkbox>
                            <div style="margin-bottom: 0.5rem" v-if="input[first.item_id][second.mtype_id] >= 0 && checkbox_data[first.item_id] != null">
                                <span style="padding: 0rem 0.2rem"> 備註 </span>
                                <input :id="'freeclinic_notes_' + second.mtype_id" :class="'freeclinic_notes ' + second.mtype_id" style="margin-right: 0.5rem; padding: 0rem 0.5rem; font-size: 1rem; line-height: 1.5" placeholder="備註" v-model="notes[second.mtype_id]" />
                            </div>
                        </template>
                    </vs-col>
                </vs-row> -->
        </div>
        <!-- 填寫人 -->
        <div class="p-4 sticky border border-solid border-l-0 border-r-0 border-b-0 border-grey flex items-center whitespace-no-wrap space-x-4 bg-white" style="bottom: -20px">
            <span>填寫人：{{ dname }}</span>
            <vs-button class="cd-button-2w rounded" @click="upsertMeasureData">送出</vs-button>
            <vs-button class="cd-button-2w rounded" @click="print">列印</vs-button>
        </div>
    </div>
</template>
<script>
import { upsertMeasureData } from '@/api/user'
export default {
    props: {
        data: {
            type: Object,
            required: true,
            default: null,
        },
        itemCode: {
            type: String,
            required: false,
            default: '',
        },
        itemList: {
            type: Array,
            required: true,
            default: [],
        },
        event_id: {
            type: Number,
            required: true,
            default: 0,
        },
    },
    inject: ['getItemName', 'getMtypeName'],
    data() {
        return {
            form: [],
            items: [],
        }
    },
    mounted() {
        const _self = this
        this.items = this.itemCode == '' || this.itemCode == 'name' ? this.itemList : [_.find(this.itemList, { item_code: this.itemCode })]
        _.forEach(this.items, function (item) {
            _.forEach(item.mtype, function (mtype) {
                let defaultValue = null
                if (_self.data[item.item_code] && _self.data[item.item_code][mtype.mtype_code]) {
                    defaultValue = _self.data[item.item_code][mtype.mtype_code].value
                }
                if (mtype.form_type == 'number') _self.$set(_self.form, mtype.mtype_code, defaultValue ? defaultValue : 0)
                if (mtype.form_type == 'radio') _self.$set(_self.form, mtype.mtype_code, defaultValue ? parseInt(defaultValue) : parseInt(0))
                if (mtype.form_type == 'checkbox') _self.$set(_self.form, mtype.mtype_code, defaultValue ? _self.$common.decimalToArray(defaultValue) : [0])

                // 特規 純音聽力圖 不知道是啥 好像是row跟col
                if (mtype.mtype_id == 36) {
                    const template = [
                        ['', '', '', '', '', '', '', '', ''],
                        ['', '', '', '', '', '', '', '', ''],
                    ]
                    const defaultValueTable = _self.data[item.item_code] && _self.data[item.item_code][mtype.mtype_code] && _self.data[item.item_code][mtype.mtype_code].value_table ? _self.data[item.item_code][mtype.mtype_code].value_table : template
                    _self.$set(_self.form, 'value_table', defaultValueTable)
                }
            })
        })
    },
    computed: {
        windowHeight() {
            return window.innerHeight
        },
        dname() {
            return this.$store.getters.name
        },
        print_url() {
            return process.env.VUE_APP_URL + '/freeclinic_print/' + this.event_id + '/' + this.data.pid
        },
    },
    methods: {
        // async updatemeasuredata() {
        //     for (const [key, value] of Object.entries(this.checkbox_data)) {
        //         for (const [key1, value1] of Object.entries(value)) {
        //             console.log(`${key1}: ${value1}`)
        //             this.input[key][key1] = this.arraytodecimal(value1)
        //         }
        //     }

        //     this.$vs.loading()
        //     let data = {
        //         data: this.input,
        //         value_table: this.value_table,
        //         eventid: this.event.eventid,
        //         pid: this.event.pid,
        //         writer: this.user_displayName,
        //         notes: this.notes,
        //     }
        //     console.log('updateMonitorData', data)
        //     await axios.post(process.env.VUE_APP_API_URL_HIS + '/updateMonitorData', { data: data }).then((res) => {
        //         // axios.post('http://localhost.his.com/updateMonitorData',{'data':data,}).then((res) => {
        //         if (res.data.status == 'OK') {
        //             this.$vs.notify({ color: 'grey', title: '新增完成', text: this.event.name + '資料新增完成', position: 'top-center' })
        //             this.popupupmeasuredata = false
        //             this.resetdata()
        //             // this.fetchMonitorEventItemList()
        //             this.getEventMeasureData()
        //         } else {
        //             this.$vs.notify({ color: 'danger', title: '新增失敗', text: res.data.message, position: 'top-center' })
        //             console.log('updateMonitorData error')
        //         }
        //     })
        //     this.$vs.loading.close()
        // },
        // 寫入量測數據
        upsertMeasureData() {
            let payload = { measures: [] }
            const _self = this

            _.forEach(this.items, function (item) {
                _.forEach(item.mtype, function (mtype) {
                    payload.measures.push({
                        event_id: _self.event_id,
                        pid: _self.data.pid,
                        item_id: item.item_id,
                        mtype_id: mtype.mtype_id,
                        value: Array.isArray(_self.form[mtype.mtype_code]) ? _self.$common.arrayToDecimal(_self.form[mtype.mtype_code]) : _self.form[mtype.mtype_code],
                        value_table: mtype.mtype_id == 36 ? _self.form['value_table'] : null, // 特規 純音聽力圖
                        writer: _self.dname,
                    })
                })
            })
            upsertMeasureData(payload).then((res) => {
                console.log(res.data.data)
                this.$bus.$emit('upsertMesasureData')
            })
        },
        print() {
            window.open(this.print_url, '_blank')
        },
    },
}
</script>
