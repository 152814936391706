<template>
    <div class="w-full" v-if="itemList.length > 0">
        <el-table :height="windowHeight - 200" :data="data" @cell-click="cellClick">
            <el-table-column label-class-name="text-black text-left" prop="name" label="姓名" sortable width="120" fixed="left" show-overflow-tooltip>
                <template v-slot="scope">
                    <div class="flex justify-start items-center text-primary cursor-pointer hover:font-semibold">
                        <span class="font-semibold">#{{ scope.row.num }} {{ scope.row.name }}</span>
                    </div>
                    <div class="flex justify-start items-center">
                        <span>{{ scope.row.account }}</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label-class-name="text-black text-left" prop="appt" label="掛號" sortable width="180" show-overflow-tooltip>
                <template v-slot="scope">
                    <div class="grid grid-cols-1 place-items-start" v-if="scope.row.appt.length > 0">
                        <span v-for="(item, index) in scope.row.appt" :key="index">#{{ item.queue_id }} {{ item.name }} 醫師</span>
                    </div>
                    <div class="grid grid-cols-1 place-items-start" v-else>
                        <span class="text-danger">尚未掛號</span>
                    </div>
                    <div class="grid grid-cols-1 place-items-start">
                        <span class="text-sm text-primary cursor-pointer hover:font-semibold" @click="makeEventAppt(scope.row)">前往掛號</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label-class-name="text-black text-left" prop="event_pdf" label="文件" width="180" show-overflow-tooltip v-if="pdf">
                <template v-slot="scope">
                    <div class="flex justify-start items-center">
                        <span class="text-success cursor-pointer hover:font-semibold" v-if="scope.row.pdf_signed_path" @click="viewSignedDoc(scope.row.pdf_signed_path)">檢視簽名文件</span>
                        <span class="text-primary cursor-pointer hover:font-semibold" v-else @click="signDoc(scope.row)">文件簽名</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column class-name="text-primary" label-class-name="text-black text-left" v-for="(item, index) in itemList" :key="index" :prop="item.item_code" :label="getItemName(item.item_code)" sortable width="180" v-if="true" show-overflow-tooltip>
                <template v-slot="scope">
                    <div class="flex justify-start items-center text-primary cursor-pointer hover:font-semibold" v-for="(mtype, index2) in item.mtype" :key="index2">
                        <span>{{ getMtypeName(item.item_code, mtype.mtype_code) }}：</span>
                        <!-- <span v-if="mtype.value_map">{{ mtype.value_map[parseInt(getValue(scope.row, item.item_code, mtype.mtype_code))] }}</span> -->
                        <span>{{ getValue(scope.row, item.item_code, mtype.mtype_code) }}</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label-class-name="text-black text-left" prop="event_question" label="問卷" sortable width="93" show-overflow-tooltip v-if="question">
                <template v-slot="scope">
                    <div class="flex justify-start items-center">
                        <span>{{ scope.row.event_question }}</span>
                    </div>
                </template>
            </el-table-column>
            <!-- <el-table-column prop="WM" :label="getItemName('WM')" sortable width="93" v-if="true" show-overflow-tooltip>
                <template v-slot="scope">
                    <div class="" v-if="scope.row.WM">
                        <span>{{ getMtypeName('WM', 'WM') }}</span>
                        <span>{{ scope.row.WM.WM }}</span>
                    </div>
                </template>
            </el-table-column> -->
            <!-- <el-table-column prop="9" id="9" :label="itemlist[9]" sortable width="93" v-if="itemcheckshow[9]" show-overflow-tooltip />
            <el-table-column prop="5" id="5" :label="itemlist[5]" sortable width="93" v-if="itemcheckshow[5]" show-overflow-tooltip />
            <el-table-column prop="2" id="2" :label="itemlist[2]" sortable width="110" v-if="itemcheckshow[2]" show-overflow-tooltip />
            <el-table-column prop="3" id="3" :label="itemlist[3]" sortable width="120" v-if="itemcheckshow[3]" show-overflow-tooltip />
            <el-table-column prop="13" id="13" :label="itemlist[13]" sortable width="110" v-if="itemcheckshow[13]" show-overflow-tooltip />
            <el-table-column prop="14" id="14" :label="itemlist[14]" sortable width="93" v-if="itemcheckshow[14]" show-overflow-tooltip />
            <el-table-column prop="4" id="4" :label="itemlist[4]" sortable width="93" v-if="itemcheckshow[4]" show-overflow-tooltip />
            <el-table-column prop="8" id="8" :label="itemlist[8]" sortable width="93" v-if="itemcheckshow[8]" show-overflow-tooltip />
            <el-table-column prop="7" id="7" :label="itemlist[7]" sortable width="140" v-if="itemcheckshow[7]" show-overflow-tooltip />
            <el-table-column prop="10" id="10" :label="itemlist[10]" sortable width="140" v-if="itemcheckshow[10]" show-overflow-tooltip />
            <el-table-column prop="11" id="11" :label="itemlist[11]" sortable width="140" v-if="itemcheckshow[11]" show-overflow-tooltip />
            <el-table-column prop="12" id="12" :label="itemlist[12]" sortable width="250" v-if="itemcheckshow[12]" show-overflow-tooltip /> -->
        </el-table>
        <!-- 輸入量測數據視窗 -->
        <vs-popup :active.sync="formActive" :title="formTitle" @close="closeForm">
            <!-- <div class="mb-base grid grid-cols-1 gap-4" v-for="item in itemList">
                {{ selectedItem }}
                <div>
                    <span>{{ getItemName(selectedItemCode) }}</span>
                </div>
                <div v-for="mtype in item.mtype">
                    <span>{{ getMtypeName(item.item_code, mtype.mtype_code) }}</span>
                    {{ selectedItem && selectedItem[mtype.mtype_code] ? selectedItem[mtype.mtype_code] : '' }}

                    <vs-input></vs-input>
                </div>
            </div> -->
            <!-- <vs-row class="mb-base" v-for="first in eventitemlist" v-if="select_type.length > 0 && (select_type[first.item_id] || select_type[0])">
                <vs-col vs-w="12" vs-xs="12" class="px-2 cd-form-group">
                    <h5>{{ itemlist[first.item_id] }}</h5>
                </vs-col>
                <vs-col vs-w="12" vs-xs="12" class="px-2 cd-form-group">
                    <vs-input type="number" v-model.number="input[first.item_id][second.mtype_id]" v-for="second in first.mtype" :label="first.number > 1 ? second.name : ''" v-if="second.form_type == 'number'" />
                </vs-col>
                <vs-col class="cd-form-group px-4 grid">
                    <template v-for="(second, index2) in first.mtype">
                        <h5 v-if="second.form_type == 'radio' || second.form_type == 'checkbox'">{{ second.name }}</h5>
                        純音聽力圖特別設定
                        <vs-row class="mb-base p-4" v-if="value_table[first.item_id] != undefined && second.mtype_id == 36">
                            <vs-col vs-type="flex">
                                <div style="width: 50px" />
                                <div class="text-center" style="width: 50px" v-for="(data, col) in tb.cols" :key="col">
                                    {{ data }}
                                </div>
                            </vs-col>
                            <vs-col vs-type="flex" vs-align="center" v-for="(data, row) in tb.rows" :key="row">
                                <div style="width: 50px">
                                    {{ data }}
                                </div>
                                <div class="flex" style="border-bottom: 1px solid rgba(0, 0, 0, 0.2); border-right: 1px solid rgba(0, 0, 0, 0.2)">
                                    <div style="width: 50px" v-for="(data, col) in tb.cols" :key="col">
                                        <vs-input type="text" class="cd-appt-input table-input" v-model="value_table[first.item_id][second.mtype_id][row][col]" @change="change_mtype_select(0, first.item_id, second.mtype_id)" />
                                    </div>
                                </div>
                            </vs-col>
                        </vs-row>

                        <vs-radio
                            class="my-1"
                            v-model="input[first.item_id][second.mtype_id]"
                            :vs-name="second.name"
                            v-for="(third, index3) in second.value_map"
                            :vs-value="index3.toString()"
                            v-if="second.form_type == 'radio'"
                            @change="change_mtype_select(third, first.item_id, second.mtype_id)"
                            :label="third"
                        >
                            {{ third }}
                        </vs-radio>

                        <vs-checkbox class="my-1" v-model="checkbox_data[first.item_id][second.mtype_id]" vs-name="kid" v-for="(third, index3) in second.value_map" :vs-value="index3" v-if="second.form_type == 'checkbox'" @change="change_mtype_select(third, first.item_id, second.mtype_id)">
                            <div style="display: flex; padding: 0rem 1rem">{{ third }}</div>
                        </vs-checkbox>
                        <div style="margin-bottom: 0.5rem" v-if="input[first.item_id][second.mtype_id] >= 0 && checkbox_data[first.item_id] != null">
                            <span style="padding: 0rem 0.2rem"> 備註 </span>
                            <input :id="'freeclinic_notes_' + second.mtype_id" :class="'freeclinic_notes ' + second.mtype_id" style="margin-right: 0.5rem; padding: 0rem 0.5rem; font-size: 1rem; line-height: 1.5" placeholder="備註" v-model="notes[second.mtype_id]" />
                        </div>
                    </template>
                </vs-col>
            </vs-row> -->
            <!-- 填寫人 -->
            <!-- <vs-row class="p-4" style="background-color: #eeeeee">
                <vs-col class="flex justify-between" style="white-space: nowrap">
                    <div>
                        <span class="mr-2" style="font-size: 1rem; white-space: nowrap">填寫人:{{ dname }}</span>
                        <vs-button class="" style="border-radius: 8px; padding: 11px" @click="updatemeasuredata">送出</vs-button>
                        &nbsp;&nbsp;
                        <a :href="print_url" class="vs-component vs-button vs-button-primary vs-button-filled" style="border-radius: 8px; padding: 13px" target="_blank"><span class="vs-button-text vs-button--text">列印</span></a>
                    </div>
                </vs-col>
            </vs-row> -->
            <FreeClinicForm :data="selectedPatient" :itemCode="selectedItemCode" :itemList="itemList" :event_id="event_id" v-if="selectedPatient"></FreeClinicForm>
        </vs-popup>
        <!-- 簽名文件 -->
        <vs-popup fullscreen :active.sync="viewSignedDocPopupActive" title="檢視簽名文件">
            <iframe :src="viewSignedDocUrl" frameborder="0" style="overflow: hidden; height: 100vh; width: 100%" width="100%" />
        </vs-popup>
    </div>
</template>
<script>
import { recordSignature, getEventItemList } from '@/api/user'
import FreeClinicForm from '@/components/FreeClinic/FreeClinicForm.vue'
export default {
    components: {
        FreeClinicForm,
    },
    data() {
        return {
            event: null,
            itemList: [],
            question: null,
            pdf: null,
            formActive: false,
            selectedPatient: null,
            selectedItemCode: '',
            formTitle: '',
            eventitemlist: [],
            print_url: '',
            viewSignedDocPopupActive: false,
            viewSignedDocUrl: '',
        }
    },
    props: {
        event_id: {
            type: Number,
            required: true,
            default: 0,
        },
        data: {
            type: Array,
            required: true,
            default: [],
        },
    },
    provide() {
        return {
            itemList: this.itemList,
            getItemName: this.getItemName,
            getMtypeName: this.getMtypeName,
        }
    },
    mounted() {
        this.getEventItemList()
        this.processSignatureReturn()
        this.createBus()
    },
    watch: {
        event_id() {
            this.getEventItemList()
        },
    },
    computed: {
        windowHeight() {
            return window.innerHeight
        },
        windowWidth() {
            return window.innerWidth
        },
        dname() {
            return this.$store.getters.name
        },
    },
    methods: {
        // 取義檢活動量測項目
        getEventItemList() {
            const payload = {
                event_id: this.event_id,
            }
            getEventItemList(payload).then((res) => {
                // console.log('getEventItemList', res.data.data.data)
                this.event = res.data.data.data[0]
                this.itemList = this.event.item_list
                this.question = this.event.event_question
                this.pdf = this.event.partner_pdf
            })
        },
        getItemName(item_code) {
            const item = _.find(this.itemList, (d) => d.item_code === item_code)

            return item ? this.$common.getLangDB(item) : null
        },
        getMtypeName(item_code, mtype_code) {
            const item = _.find(this.itemList, (d) => d.item_code === item_code)
            const mtype = _.find(item.mtype, (d) => d.mtype_code === mtype_code)

            return mtype ? this.$common.getLangDB(mtype) : null
        },
        getValue(data, item_code, mtype_code) {
            if (!data) return '-'
            if (!data[item_code]) return '-'
            if (!data[item_code][mtype_code]) return '-'
            const item = _.find(this.itemList, (d) => d.item_code === item_code)
            const mtype = _.find(item.mtype, (d) => d.mtype_code === mtype_code)
            if (mtype.form_type == 'number') return data[item_code][mtype_code].value
            if (mtype.form_type == 'radio') {
                let value = parseInt(data[item_code][mtype_code].value)
                return mtype.value_map[value]
            }
            if (mtype.form_type == 'checkbox') {
                let array = this.$common.decimalToArray(data[item_code][mtype_code].value)
                array = _.map(array, function (item) {
                    return mtype.value_map[item]
                })
                return _.join(array, ',')
            }
            return '-'
        },
        cellClick(row, column, cell, event) {
            if (column.property == 'appt' || column.property == 'event_pdf') return
            if (column.property == 'event_question') {
                const url = process.env.VUE_APP_API_URL_EVENT + '/freeclinic/' + this.question.code + '/' + row.mid + '/' + row.pid + '?back=1'
                if (localStorage.getItem('doc_access_phoneType') == 2) {
                    window.open(url, '_system')
                } else {
                    location.href = url
                }
            } else {
                this.selectedPatient = row
                this.selectedItemCode = column.property
                this.formTitle = '輸入' + row.name + '量測數據'
                this.formActive = true
                // if (column.property != 'account' && column.property != 'name') {
                //     this.select_type[column.property] = true
                // } else {
                //     //點帳號姓名可輸入全部
                //     this.select_type[0] = true
                // }
                // await this.fetchMonitorDataByPid(row.pid)
                // this.event.pid = row.pid
                // this.event.name = row.name
                // this.popupItem = _.find(this.eventitemlist, function (o) {
                //     return o.item_id == column.property
                // })
                // // 選擇純音聽力圖時，填入過去資料報表格中
                // if (this.popupItem != undefined && this.popupItem.item_id == 11) {
                //     this.popupItem.mtype.forEach((a) => {
                //         if (a.value_table_map !== null) {
                //             this.tb.cols = a.value_table_map.cols
                //             this.tb.rows = a.value_table_map.rows
                //         }
                //     })
                // }
                // this.selectedItemCode = column.property
                // const items = _.find(this.data, (d) => d.pid === row.pid)
                // console.log('items', items)
                // this.selectedItem = items && items[column.property] ? items[column.property] : null
                // console.log('selectedItemCode', this.selectedItemCode)
                // console.log('selectedItem', this.selectedItem)
            }
        },
        // cellStyle({ row, column, rowIndex, columnIndex }) {
        //     return ''
        //     if (columnIndex == 0) {
        //         return 'white-space:nowrap;overflow:hidden;text-overflow:ellipsis;color:blue;'
        //     }
        //     if (columnIndex == 1) {
        //         return 'white-space:nowrap;overflow:hidden;text-overflow:ellipsis;'
        //     }
        //     if (row[column.property] == '-' || row[column.property] == '-/-' || row[column.property] == '-/-/-' || row[column.property] == '-/-/-/-' || row[column.property] == '-/-/-/-/-' || row[column.property] == '-/- -') {
        //         row[column.property] = '-'
        //         return 'white-space:nowrap;overflow:hidden;text-overflow:ellipsis;color:red;'
        //     }
        //     if (column.property == 12) return 'width:15em;color:blue;'
        //     return 'white-space:nowrap;overflow:hidden;text-overflow:ellipsis;color:blue;'
        // },
        openForm() {
            this.formActive = true
        },
        closeForm() {
            this.selectedPatient = null
            this.formActive = false
            // this.resetdata()
        },
        makeEventAppt(data) {
            const eventPatient = {
                id: data.event_patient_id,
                pid: data.pid,
                event_id: this.event_id,
            }
            this.$bus.$emit('makeEventAppt', eventPatient)
        },
        // updatemeasuredata() {},
        // 檢視簽名文件
        viewSignedDoc(url) {
            this.viewSignedDocUrl = 'https://patient.curdoctor.com.tw/PDFviewer/web/viewer.html?file=' + url
            this.viewSignedDocPopupActive = true
        },
        // 文件簽名
        async signDoc(row) {
            const apiSignatureUrl = process.env.VUE_APP_API_URL + '/app/restful_api/signature/getSignatureUrl'
            const returnUrl = process.env.VUE_APP_URL + '/freeclinic'
            // const returnUrl = 'http://localhost:8080/freeclinic'
            const data = {
                pdf_path: this.pdf.pdf_path,
                sign_config: this.pdf.sign_config,
                info_config: this.pdf.info_config,
                return_url: returnUrl,
                return_info: {
                    mid: row.mid,
                    partnerid: this.event.partnerid,
                    enterpriseid: this.event.enterpriseid,
                    partner_pdf_id: this.pdf.id,
                },
                info: {
                    yyyy: this.$moment().year(),
                    yy: this.$moment().year() - 1911,
                    m: this.$moment().month() + 1,
                    d: this.$moment().date(),
                },
            }
            await axios.post(apiSignatureUrl, data).then((res) => {
                const { status, url } = res.data
                if (status == 'OK') {
                    window.location.href = url
                } else {
                    this.$vs.notify({ color: 'danger', title: '連結數位簽名失敗', text: res.data.message, position: 'top-center' })
                }
            })
        },
        // 處理電子簽名的回傳
        processSignatureReturn() {
            console.log('this.$route.query => ', this.$route.query)
            const { status, sign_id, pdf_signed_path, return_info } = this.$route.query
            if (status != 'success') return
            if (!sign_id) return
            if (!pdf_signed_path) return
            this.$router.replace({
                path: this.$route.path,
                query: {},
            })
            const parsed_return_info = return_info ? JSON.parse(return_info) : null
            const data = {
                partner_id: parsed_return_info.partnerid,
                eid: parsed_return_info.enterpriseid,
                partner_pdf_id: parsed_return_info.partner_pdf_id,
                mid: parsed_return_info.mid,
                pdf_signed_path: pdf_signed_path,
                more: return_info,
            }
            recordSignature(data).then((res) => {
                if (res.data.status == 'OK') {
                    this.$vs.notify({ color: 'gray', title: '數位簽名成功', position: 'top-center' })
                    // this.fetchMonitorMemberList()
                    this.getEventMeasureData()
                } else {
                    this.$vs.notify({ color: 'danger', title: '數位簽名失敗', position: 'top-center' })
                }
            })
        },
        // 建立 event bus
        createBus() {
            this.$bus.$on('upsertMesasureData', () => {
                this.closeForm()
            })
        },
    },
    beforeDestroy() {
        this.$bus.$off('upsertMesasureData')
    },
}
</script>
