<template>
    <div class="cd-el-select">
        <div class="p-4 flex justify-between items-center flex-wrap bg-white whitespace-no-wrap">
            <div class="flex items-center flex-wrap space-x-4">
                <span class="text-2xl"
                    >義檢<span v-if="event">：{{ event.name }}</span></span
                >

                <vs-dropdown vs-trigger-click class="text-primary cursor-pointer hover:font-semibold" v-if="events.length > 1">
                    <span>切換活動</span>
                    <vs-dropdown-menu class="w-64 whitespace-no-wrap overflow-hidden">
                        <vs-dropdown-item v-for="item in events" :key="item.id" @click="event_id = item.id">{{ item.eventtime + ' | ' + item.name }}</vs-dropdown-item>
                    </vs-dropdown-menu>
                </vs-dropdown>
                <span v-if="!event">{{ $t('freeclinic.freeclinicEventList') }}</span>
            </div>
            <div class="my-2 flex items-center flex-wrap space-x-4" v-if="event">
                <vs-input icon-no-border clearable icon="icon-search" icon-pack="feather" class="w-32 rounded" v-model="query" />
                <div>
                    <span class="text-primary cursor-pointer hover:font-semibold" @click="refresh">刷新</span>
                </div>
                <vs-button class="cd-button-2w rounded" @click="openForm">＋ 報名</vs-button>
            </div>
        </div>
        <vs-row class="py-2" v-if="event_id > 0 && measureData">
            <vs-col vs-type="flex" vs-justify="center" vs-align="center">
                <!-- <el-table class="cursor-pointer" :height="windowHeight - 200" :weight="windowWidth" :data="item_memberlist" :default-sort="{ prop: 'account', order: 'ascending' }" ref="monitor_list_table" @cell-click="select_popup" :cell-style="cellStyle" style="padding: 0px">
                    <el-table-column prop="name" label="姓名" sortable width="93" fixed="left" show-overflow-tooltip />
                    <el-table-column prop="account" label="身分證" sortable width="140" show-overflow-tooltip />
                    <el-table-column prop="event_pdf" label="文件" width="280" show-overflow-tooltip v-if="event.pdf.path != null">
                        <template v-slot="scope">
                            <el-button type="success" v-if="scope.row.pdf_signed_path" @click="viewSignedDoc(scope.row.pdf_signed_path)">檢視簽名文件</el-button>
                            <el-button type="primary" @click="signDoc(scope.row)">文件簽名</el-button>
                        </template>
                    </el-table-column>
                    <el-table-column prop="6" id="6" :label="itemlist[6]" sortable width="93" v-if="itemcheckshow[6]" show-overflow-tooltip />
                    <el-table-column prop="9" id="9" :label="itemlist[9]" sortable width="93" v-if="itemcheckshow[9]" show-overflow-tooltip />
                    <el-table-column prop="5" id="5" :label="itemlist[5]" sortable width="93" v-if="itemcheckshow[5]" show-overflow-tooltip />
                    <el-table-column prop="2" id="2" :label="itemlist[2]" sortable width="110" v-if="itemcheckshow[2]" show-overflow-tooltip />
                    <el-table-column prop="3" id="3" :label="itemlist[3]" sortable width="120" v-if="itemcheckshow[3]" show-overflow-tooltip />
                    <el-table-column prop="13" id="13" :label="itemlist[13]" sortable width="110" v-if="itemcheckshow[13]" show-overflow-tooltip />
                    <el-table-column prop="14" id="14" :label="itemlist[14]" sortable width="93" v-if="itemcheckshow[14]" show-overflow-tooltip />
                    <el-table-column prop="4" id="4" :label="itemlist[4]" sortable width="93" v-if="itemcheckshow[4]" show-overflow-tooltip />
                    <el-table-column prop="8" id="8" :label="itemlist[8]" sortable width="93" v-if="itemcheckshow[8]" show-overflow-tooltip />
                    <el-table-column prop="7" id="7" :label="itemlist[7]" sortable width="140" v-if="itemcheckshow[7]" show-overflow-tooltip />
                    <el-table-column prop="10" id="10" :label="itemlist[10]" sortable width="140" v-if="itemcheckshow[10]" show-overflow-tooltip />
                    <el-table-column prop="11" id="11" :label="itemlist[11]" sortable width="140" v-if="itemcheckshow[11]" show-overflow-tooltip />
                    <el-table-column prop="12" id="12" :label="itemlist[12]" sortable width="250" v-if="itemcheckshow[12]" show-overflow-tooltip />
                    <el-table-column prop="event_question" label="問卷" sortable width="93" show-overflow-tooltip v-if="event.question_code != null" />
                </el-table> -->
                <FreeClinicTable :event_id="event_id" :data="measureData"></FreeClinicTable>
            </vs-col>
        </vs-row>
        <vs-popup :active.sync="activeForm" title="義檢活動報名" @close="closeForm">
            <SuccessForm :joinSuccess="joinSuccess" :apptSuccess="apptSuccess"></SuccessForm>
            <JoinForm :data="event" :active="activeForm" v-if="event && !eventPatient"></JoinForm>
            <ApptForm :data="eventPatient" v-if="eventPatient && !apptSuccess"></ApptForm>
        </vs-popup>
        <!-- 輸入量測數據視窗 -->
        <!-- <vs-popup class="px-2" :active.sync="popupupmeasuredata" :title="'輸入' + this.event.name + '量測數據'" @close="closepopup">
            <vs-row class="mb-base" v-for="first in eventitemlist" v-if="select_type.length > 0 && (select_type[first.item_id] || select_type[0])">
                <vs-col vs-w="12" vs-xs="12" class="px-2 cd-form-group">
                    <h5>{{ itemlist[first.item_id] }}</h5>
                </vs-col>
                <vs-col vs-w="12" vs-xs="12" class="px-2 cd-form-group">
                    <vs-input type="number" v-model.number="input[first.item_id][second.mtype_id]" v-for="second in first.mtype" :label="first.number > 1 ? second.name : ''" v-if="second.form_type == 'number'" />
                </vs-col>
                <vs-col class="cd-form-group px-4 grid">
                    <template v-for="(second, index2) in first.mtype">
                        <h5 v-if="second.form_type == 'radio' || second.form_type == 'checkbox'">{{ second.name }}</h5>
                        純音聽力圖特別設定
                        <vs-row class="mb-base p-4" v-if="value_table[first.item_id] != undefined && second.mtype_id == 36">
                            <vs-col vs-type="flex">
                                <div style="width: 50px" />
                                <div class="text-center" style="width: 50px" v-for="(data, col) in tb.cols" :key="col">
                                    {{ data }}
                                </div>
                            </vs-col>
                            <vs-col vs-type="flex" vs-align="center" v-for="(data, row) in tb.rows" :key="row">
                                <div style="width: 50px">
                                    {{ data }}
                                </div>
                                <div class="flex" style="border-bottom: 1px solid rgba(0, 0, 0, 0.2); border-right: 1px solid rgba(0, 0, 0, 0.2)">
                                    <div style="width: 50px" v-for="(data, col) in tb.cols" :key="col">
                                        <vs-input type="text" class="cd-appt-input table-input" v-model="value_table[first.item_id][second.mtype_id][row][col]" @change="change_mtype_select(0, first.item_id, second.mtype_id)" />
                                    </div>
                                </div>
                            </vs-col>
                        </vs-row>

                        <vs-radio
                            class="my-1"
                            v-model="input[first.item_id][second.mtype_id]"
                            :vs-name="second.name"
                            v-for="(third, index3) in second.value_map"
                            :vs-value="index3.toString()"
                            v-if="second.form_type == 'radio'"
                            @change="change_mtype_select(third, first.item_id, second.mtype_id)"
                            :label="third"
                        >
                            {{ third }}
                        </vs-radio>

                        <vs-checkbox class="my-1" v-model="checkbox_data[first.item_id][second.mtype_id]" vs-name="kid" v-for="(third, index3) in second.value_map" :vs-value="index3" v-if="second.form_type == 'checkbox'" @change="change_mtype_select(third, first.item_id, second.mtype_id)">
                            <div style="display: flex; padding: 0rem 1rem">{{ third }}</div>
                        </vs-checkbox>
                        <div style="margin-bottom: 0.5rem" v-if="input[first.item_id][second.mtype_id] >= 0 && checkbox_data[first.item_id] != null">
                            <span style="padding: 0rem 0.2rem"> 備註 </span>
                            <input :id="'freeclinic_notes_' + second.mtype_id" :class="'freeclinic_notes ' + second.mtype_id" style="margin-right: 0.5rem; padding: 0rem 0.5rem; font-size: 1rem; line-height: 1.5" placeholder="備註" v-model="notes[second.mtype_id]" />
                        </div>
                    </template>
                </vs-col>
            </vs-row>
            填寫人
            <vs-row class="p-4" style="background-color: #eeeeee">
                <vs-col class="flex justify-between" style="white-space: nowrap">
                    <div>
                        <span class="mr-2" style="font-size: 1rem; white-space: nowrap">填寫人:{{ user_displayName }}</span>
                        <vs-button class="" style="border-radius: 8px; padding: 11px" @click="updatemeasuredata">送出</vs-button>
                        &nbsp;&nbsp;
                        <a :href="print_url" class="vs-component vs-button vs-button-primary vs-button-filled" style="border-radius: 8px; padding: 13px" target="_blank"><span class="vs-button-text vs-button--text">列印</span></a>
                    </div>
                </vs-col>
            </vs-row>
        </vs-popup> -->
        <!-- <vs-popup fullscreen :active.sync="viewSignedDocPopupActive" title="檢視簽名文件">
            <iframe :src="viewSignedDocUrl" frameborder="0" style="overflow: hidden; height: 100vh; width: 100%" width="100%" />
        </vs-popup> -->
    </div>
</template>

<script>
import FreeClinicTable from '@/components/FreeClinic/FreeClinicTable.vue'
import JoinForm from '@/components/FreeClinic/JoinForm.vue'
import ApptForm from '@/components/FreeClinic/ApptForm.vue'
import SuccessForm from '@/components/FreeClinic/SuccessForm.vue'
import { getEventMeasureData, getEvents } from '@/api/user'
export default {
    components: { FreeClinicTable, JoinForm, ApptForm, SuccessForm },
    data() {
        return {
            query: '',
            measureData: [],
            event_id: 0,
            events: [],
            activeForm: false,
            eventPatient: null,
            joinSuccess: false,
            apptSuccess: false,
            // event: {
            //     eventid: 0,
            //     itemid: 0,
            //     pid: 0,
            //     name: '',
            //     question_id: 0,
            //     question_code: '',
            //     enterpriseid: 0,
            //     pdf: {
            //         partner_pdf_id: undefined,
            //         path: undefined,
            //         sign_config: {},
            //         info_config: {},
            //     },
            // },
            // itemlist: [],
            // item_memberlist: [],
            // memberlist: [],
            // personal_history_data: [],
            // input: {},
            // eventlist: [],
            // stationConfig: [],
            // maintable: [],
            // eventitemlist: [],
            // itemcheckshow: [],
            // value_map: [],
            // mtype_name: [],
            // select_type: [],
            // popupupmeasuredata: false, //輸入視窗控制
            // popupItem: null,
            // tb: {
            //     cols: null,
            //     rows: null,
            // },
            // notes: {},
            // checkbox_data: [],
            // value_table: {},
            // print_url: '',
            // viewSignedDocPopupActive: false,
            // viewSignedDocUrl: '',
            // eid: 0,
        }
    },
    watch: {
        event_id() {
            // 在更換活動的時後，更新單位id
            // const selectedItem = this.eventlist.find((item) => item.eventid == newEventid)
            // if (selectedItem) {
            //     this.event.enterpriseid = selectedItem.enterpriseid
            // }
            // this.fetchMonitorEventItemList()
            this.getEventMeasureData()
        },
        // 'event.itemid': function () {
        // this.fetchMonitorMemberList()
        // this.getEventMeasureData()
        // },
        // 'event.pid': function () {
        // myHeaders.append('Authorization', 'Bearer '+ localStorage.getItem('di_tokenId'));
        // console.log('Bearer '+ localStorage.getItem('di_tokenId'));
        // localStorage.setItem('authToken', 'Bearer '+ localStorage.getItem('di_tokenId'));
        // this.print_url = process.env.VUE_APP_URL + '/freeclinic_print/' + this.event.eventid + '/' + this.event.pid
        // console.log('pid')
        // },
        query() {
            this.getEventMeasureData()
        },
    },
    computed: {
        did() {
            return this.$store.getters.did
        },
        event() {
            const data = _.find(this.events, (d) => d.id === this.event_id)
            return data ? data : null
        },
        // windowHeight() {
        //     return window.innerHeight
        // },
        // windowWidth() {
        //     return window.innerWidth
        // },
        // user_displayName() {
        //     return this.$store.getters.name
        // },
        // partnerid() {
        //     return this.$store.getters.partner
        // },
    },
    mounted() {
        // 取活動列表
        this.getEvents()
        setInterval(() => {
            this.refresh()
        }, 20000)
    },
    created() {
        this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', false)
        this.createBus()
    },
    methods: {
        // 取活動清單
        getEvents() {
            const payload = {
                did: this.did,
            }
            getEvents(payload).then((res) => {
                this.events = res.data.data.data

                // 預設值
                this.event_id = this.events[0].id
            })
        },
        // 取義診活動所有會員的量測數據
        getEventMeasureData() {
            const payload = {
                event_id: this.event_id,
                query: this.query,
            }
            getEventMeasureData(payload).then((res) => {
                this.measureData = res.data.data.data
                if (this.$refs.monitor_list_table) {
                    this.$nextTick(() => {
                        this.$refs.monitor_list_table.doLayout()
                    })
                }
            })
        },
        //自動化更新數據
        fetchdeviceData() {
            axios.get(process.env.VUE_APP_API_URL_HIS + '/fetchdeviceData/' + this.event_id)
        },
        //刷新
        async refresh() {
            this.$vs.loading()
            try {
                await Promise.all([this.fetchdeviceData(), this.getEventMeasureData()])
            } finally {
                this.$vs.loading.close()
            }
        },
        // 建立 event bus
        createBus() {
            this.$bus.$on('upsertMesasureData', () => {
                this.getEventMeasureData()
            })
            this.$bus.$on('joinEvent', (data) => {
                this.getEventMeasureData()
                this.eventPatient = data
                this.joinSuccess = true
            })
            this.$bus.$on('apptEvent', () => {
                this.getEventMeasureData()
                this.joinSuccess = false
                this.apptSuccess = true
            })
            this.$bus.$on('close', () => {
                this.closeForm()
            })
            this.$bus.$on('makeEventAppt', (data) => {
                this.eventPatient = data
                this.openForm()
            })
        },
        openForm() {
            this.activeForm = true
        },
        closeForm() {
            this.eventPatient = null
            this.joinSuccess = false
            this.apptSuccess = false
            this.activeForm = false
        },
        // notify(color, title, text) {
        //     this.$vs.notify({
        //         color: color,
        //         title: title,
        //         text: text,
        //         time: 8000,
        //         position: 'top-center',
        //     })
        // },
        // 取活動人員列表
        // fetchMonitorMemberList() {
        //     axios.get(process.env.VUE_APP_API_URL_HIS + '/fetchMonitorMemberList/' + this.event.eventid + '/' + this.event.itemid).then((res) => {
        //         if (res.data.status == 'OK') {
        //             this.item_memberlist = res.data.list
        //             if (this.$refs.monitor_list_table) {
        //                 this.$nextTick(() => {
        //                     this.$refs.monitor_list_table.doLayout()
        //                 })
        //             }
        //         } else {
        //             console.log('get EventItem error')
        //         }
        //     })
        // },

        // async updatemeasuredata() {
        //     for (const [key, value] of Object.entries(this.checkbox_data)) {
        //         for (const [key1, value1] of Object.entries(value)) {
        //             console.log(`${key1}: ${value1}`)
        //             this.input[key][key1] = this.arraytodecimal(value1)
        //         }
        //     }

        //     this.$vs.loading()
        //     let data = {
        //         data: this.input,
        //         value_table: this.value_table,
        //         eventid: this.event.eventid,
        //         pid: this.event.pid,
        //         writer: this.user_displayName,
        //         notes: this.notes,
        //     }
        //     console.log('updateMonitorData', data)
        //     await axios.post(process.env.VUE_APP_API_URL_HIS + '/updateMonitorData', { data: data }).then((res) => {
        //         // axios.post('http://localhost.his.com/updateMonitorData',{'data':data,}).then((res) => {
        //         if (res.data.status == 'OK') {
        //             this.$vs.notify({ color: 'grey', title: '新增完成', text: this.event.name + '資料新增完成', position: 'top-center' })
        //             this.popupupmeasuredata = false
        //             this.resetdata()
        //             // this.fetchMonitorEventItemList()
        //             this.getEventMeasureData()
        //         } else {
        //             this.$vs.notify({ color: 'danger', title: '新增失敗', text: res.data.message, position: 'top-center' })
        //             console.log('updateMonitorData error')
        //         }
        //     })
        //     this.$vs.loading.close()
        // },
        // open popup
        // async select_popup(row, column, cell, event) {
        //     if (column.property == 'event_question') {
        //         const url = process.env.VUE_APP_API_URL_EVENT + '/freeclinic/' + this.event.question_code + '/' + row.mid + '/' + row.pid + '?back=1'
        //         if (localStorage.getItem('doc_access_phoneType') == 2) {
        //             window.open(url, '_system')
        //         } else {
        //             location.href = url
        //         }
        //     } else if (column.property == 'event_pdf') {
        //     } else {
        //         if (column.property != 'account' && column.property != 'name') {
        //             this.select_type[column.property] = true
        //         } else {
        //             //點帳號姓名可輸入全部
        //             this.select_type[0] = true
        //         }
        //         await this.fetchMonitorDataByPid(row.pid)

        //         this.event.pid = row.pid
        //         this.event.name = row.name
        //         this.popupItem = _.find(this.eventitemlist, function (o) {
        //             return o.item_id == column.property
        //         })
        //         // 選擇純音聽力圖時，填入過去資料報表格中
        //         if (this.popupItem != undefined && this.popupItem.item_id == 11) {
        //             this.popupItem.mtype.forEach((a) => {
        //                 if (a.value_table_map !== null) {
        //                     this.tb.cols = a.value_table_map.cols
        //                     this.tb.rows = a.value_table_map.rows
        //                 }
        //             })
        //         }
        //         this.popupupmeasuredata = true
        //     }
        // },
        // change_mtype_select(index, item_id, mtype_id) {
        //     // console.log('bbb', index, item_id, mtype_id)
        //     // 聽力部分 預設先給已上傳未分析
        //     if (item_id == 11 && mtype_id == 36 && this.input[item_id][mtype_id] == null) {
        //         this.input[item_id][mtype_id] = this.input[item_id][mtype_id] ? this.input[item_id][mtype_id] : '99'
        //     }
        //     this.$forceUpdate()
        // },
        // input_note_select(option_index, item_id, mtype_id) {
        //     console.log(option_index, item_id, mtype_id)
        // },
        // fetchconfig() {
        //     axios.get(process.env.VUE_APP_API_URL_HIS + '/fetchFreeClinicConfig/measuredata/item').then((res) => {
        //         // axios.get('http://localhost.his.com/fetchFreeClinicConfig/measuredata/item').then((res) => {
        //         if (res.data.status == 'OK') {
        //             this.stationConfig = res.data.data
        //         } else {
        //             console.log('get config error')
        //         }
        //     })
        // },

        //義檢項目列表
        // fetchMonitorEventItemList() {
        //     axios.get(process.env.VUE_APP_API_URL_HIS + '/fetchMonitorEventItemList/' + this.event.eventid + '/1').then((res) => {
        //         if (res.data.status == 'OK') {
        //             this.itemcheckshow = []
        //             this.eventitemlist = res.data.eventitemlist
        //             this.eventitemlist.forEach((e) => {
        //                 this.itemlist[e.item_id] = e.name
        //                 this.itemcheckshow[e.item_id] = true
        //                 e.mtype.forEach((ee) => {
        //                     this.mtype_name[ee.mtype_id] = ee.name
        //                     this.value_map[e.item_id] = this.value_map[e.item_id] || {}
        //                     this.value_map[e.item_id][ee.mtype_id] = ee.value_map
        //                 })
        //             })
        //             this.event.question_id = res.data.question.id
        //             this.event.question_code = res.data.question.code
        //             this.event.pdf.path = res.data.pdf.path
        //             this.event.pdf.partner_pdf_id = res.data.pdf.partner_pdf_id
        //             this.event.pdf.sign_config = JSON.parse(res.data.pdf.sign_config)
        //             this.event.pdf.info_config = JSON.parse(res.data.pdf.info_config)
        //             this.fetchMonitorMemberList()
        //         } else {
        //             console.log('get EventItem error')
        //         }
        //     })
        // },
        //民眾已填寫資料
        // async fetchMonitorDataByPid(spid) {
        //     await axios.get(process.env.VUE_APP_API_URL_HIS + '/fetchMonitorDataByPid/' + this.event.eventid + '/' + spid).then((res) => {
        //         if (res.data.status == 'OK') {
        //             this.personal_history_data = []
        //             this.checkbox_data = []
        //             console.log('DataList', res.data.rs)
        //             var Datalist = res.data.rs
        //             Datalist.forEach((e) => {
        //                 this.personal_history_data[e.item_id] = e.data
        //                 e.data.forEach((ee) => {
        //                     if (this.input[e.item_id] == undefined)
        //                         // 渲染動態生成的陣列內容
        //                         this.$set(this.input, e.item_id, {})
        //                     if (ee.form_type == 'checkbox') {
        //                         if (this.checkbox_data[e.item_id] == undefined) {
        //                             this.checkbox_data[e.item_id] = []
        //                             // 渲染動態生成的陣列內容
        //                             this.$set(this.checkbox_data[e.item_id], ee.mtype_id, {})
        //                         }
        //                         this.input[e.item_id][ee.mtype_id] = ee.value
        //                         this.checkbox_data[e.item_id][ee.mtype_id] = this.decimaltoarray(ee.value)
        //                         this.notes[ee.mtype_id] = ee.notes
        //                     } else if (ee.form_type == 'radio') {
        //                         this.input[e.item_id][ee.mtype_id] = ee.value
        //                         if (this.value_table[e.item_id] == undefined) {
        //                             this.value_table[e.item_id] = {}
        //                             // 渲染動態生成的陣列內容
        //                             this.$set(this.value_table[e.item_id], ee.mtype_id, {})
        //                         }
        //                         //聽力特別處理
        //                         if (ee.mtype_id == 36) {
        //                             console.log(this.value_table[e.item_id][ee.mtype_id], ee.value_table)
        //                             this.value_table[e.item_id][ee.mtype_id] = ee.value_table != null ? ee.value_table : [[], []]
        //                         }
        //                     } else {
        //                         // 渲染動態生成的陣列內容
        //                         this.$set(this.input[e.item_id], ee.mtype_id, ee.value ? ee.value : null)
        //                     }
        //                 })
        //             })
        //         } else {
        //             console.log('get EventItem error')
        //         }
        //     })
        // },
        //義檢活動列表
        // fetcheventlist() {
        //     axios.get(process.env.VUE_APP_API_URL_HIS + '/fetchMonitorEventList/' + this.did).then((res) => {
        //         if (res.data.status == 'OK') {
        //             this.eventlist = res.data.eventlist
        //             //預設活動為最新活動
        //             this.event.eventid = this.eventlist[0].eventid
        //             this.event.enterpriseid = this.eventlist[0].enterpriseid
        //         }
        //     })
        // },
        // resetdata() {
        //     this.select_type = []
        // },
        // 視窗通知

        // arraytodecimal(array) {
        //     if (!array) return null

        //     return array.reduce((acc, val) => acc + (1 << val), 0)
        // },
        // decimaltoarray(index) {
        //     const atemp = Array.from({ length: 14 }, (_, i) => i)
        //     const array = []

        //     for (let i = 0; index > 0; i++, index >>= 1) {
        //         if (index & 1) {
        //             array.push(atemp[i])
        //         }
        //     }

        //     return array
        // },
        // cellStyle({ row, column, rowIndex, columnIndex }) {
        //     if (columnIndex == 0) {
        //         return 'white-space:nowrap;overflow:hidden;text-overflow:ellipsis;color:blue;'
        //     }
        //     if (columnIndex == 1) {
        //         return 'white-space:nowrap;overflow:hidden;text-overflow:ellipsis;'
        //     }
        //     if (row[column.property] == '-' || row[column.property] == '-/-' || row[column.property] == '-/-/-' || row[column.property] == '-/-/-/-' || row[column.property] == '-/-/-/-/-' || row[column.property] == '-/- -') {
        //         row[column.property] = '-'
        //         return 'white-space:nowrap;overflow:hidden;text-overflow:ellipsis;color:red;'
        //     }
        //     if (column.property == 12) return 'width:15em;color:blue;'
        //     return 'white-space:nowrap;overflow:hidden;text-overflow:ellipsis;color:blue;'
        // },
        // closepopup() {
        //     this.resetdata()
        // },
        // to_print_page() {
        //     this.$router.push('/freeclinic_print/' + this.event.eventid + '/' + this.event.pid).catch(() => {
        //         console.log('error router push')
        //     })
        //     this.popupupmeasuredata = false
        // },
        // 處理電子簽名的回傳
        // processSignatureReturn() {
        //     console.log('this.$route.query => ', this.$route.query)
        //     const { status, sign_id, pdf_signed_path, return_info } = this.$route.query
        //     if (status != 'success') return
        //     if (!sign_id) return
        //     if (!pdf_signed_path) return
        //     this.$router.replace({
        //         path: this.$route.path,
        //         query: {},
        //     })
        //     const parsed_return_info = return_info ? JSON.parse(return_info) : null
        //     const data = {
        //         partner_id: parsed_return_info.partnerid,
        //         eid: parsed_return_info.enterpriseid,
        //         partner_pdf_id: parsed_return_info.partner_pdf_id,
        //         mid: parsed_return_info.mid,
        //         pdf_signed_path: pdf_signed_path,
        //         more: return_info,
        //     }
        //     recordSignature(data).then((res) => {
        //         if (res.data.status == 'OK') {
        //             this.$vs.notify({ color: 'gray', title: '數位簽名成功', position: 'top-center' })
        //             // this.fetchMonitorMemberList()
        //             this.getEventMeasureData()
        //         } else {
        //             this.$vs.notify({ color: 'danger', title: '數位簽名失敗', position: 'top-center' })
        //         }
        //     })
        // },
        // 檢視簽名文件
        // viewSignedDoc(url) {
        //     this.viewSignedDocUrl = 'https://patient.curdoctor.com.tw/PDFviewer/web/viewer.html?file=' + url
        //     this.viewSignedDocPopupActive = true
        // },
        // 文件簽名
        // async signDoc(row) {
        //     const apiSignatureUrl = process.env.VUE_APP_API_URL + '/app/restful_api/signature/getSignatureUrl'
        //     const data = {
        //         pdf_path: this.event.pdf.path,
        //         sign_config: this.event.pdf.sign_config,
        //         info_config: this.event.pdf.info_config,
        //         return_url: process.env.VUE_APP_URL + '/freeclinic',
        //         return_info: {
        //             mid: row.mid,
        //             partnerid: this.partnerid,
        //             enterpriseid: this.event.enterpriseid,
        //             partner_pdf_id: this.event.pdf.partner_pdf_id,
        //         },
        //         info: {
        //             yyyy: this.$moment().year(),
        //             yy: this.$moment().year() - 1911,
        //             m: this.$moment().month() + 1,
        //             d: this.$moment().date(),
        //         },
        //     }
        //     await axios.post(apiSignatureUrl, data).then((res) => {
        //         const { status, url } = res.data
        //         if (status == 'OK') {
        //             window.location.href = url
        //         } else {
        //             this.$vs.notify({ color: 'danger', title: '連結數位簽名失敗', text: res.data.message, position: 'top-center' })
        //         }
        //     })
        // },
    },
    beforeDestroy() {
        this.$bus.$off('upsertMesasureData')
        this.$bus.$off('joinEvent')
        this.$bus.$off('apptEvent')
        this.$bus.$off('close')
    },
}
</script>
<!-- <style>
.table-input .vs-input--input {
    border-radius: 0px;
    max-width: 50px;
    border-bottom: 0px !important;
    border-right: 0px !important;
}
</style> -->
