<template>
    <div class="grid grid-cols-1 gap-8">
        <div>
            <span class="text-lg">選擇掛號醫師</span>
        </div>
        <div class="grid grid-cols-1 gap-4" v-for="item in eventDoctors" :key="item.id">
            <vs-checkbox v-model="dids" :vs-value="item.did">{{ item.doctor_info.family_name + item.doctor_info.name }}</vs-checkbox>
        </div>
        <div class="grid grid-cols-1 gap-4">
            <vs-button class="rounded whitespace-no-wrap" @click="makeEventAppt">掛號</vs-button>
        </div>
    </div>
</template>
<script>
import { getEvents, makeEventAppt } from '@/api/user'
export default {
    props: {
        data: {
            type: Object,
            required: true,
            default: null,
        },
    },
    data() {
        return {
            eventDoctors: [],
            dids: [],
        }
    },
    mounted() {
        this.getEventDoctors()
    },
    methods: {
        // 取活動醫師清單
        getEventDoctors() {
            const payload = {
                event_id: this.data.event_id,
                withEventDoctor: 1,
            }
            getEvents(payload).then((res) => {
                console.log('eventDoctors', res.data.data.data[0].event_doctor)
                this.eventDoctors = res.data.data.data[0].event_doctor
            })
        },
        // 民眾義檢掛號
        makeEventAppt() {
            const payload = {
                event_patient_id: this.data.id,
                dids: this.dids,
            }
            makeEventAppt(payload).then((res) => {
                console.log('eventPatient', res.data.data)
                this.$bus.$emit('apptEvent', res.data.data)
            })
        },
    },
}
</script>
